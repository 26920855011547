.datenschutzDetailsModal {
  .dialog-title {
    text-align: right;
  }

  .dialog-h3 {
    margin-top: 10px;
  }

  .dialog-ul {
    list-style-type: none;
  }
}
