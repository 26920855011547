@import "../styles/variables.scss";

.slide-3 {
  margin-top: 15px;
  width: 100%;
  height: 100%;

  h2 {
    margin-top: 20px;

    span {
      color: $white;
    }
  }

  .smile-icon {
    width: 150px;
    margin: 20px 0 40px;
    color: $white;
  }
}
