@import "../styles/variables.scss";

.slide-1 {
  width: 100%;
  font-family: Calibre;
  margin-bottom: 50px;

  .slide-title {
    text-transform: uppercase;
    text-align: left;
    font-size: 52px;
    font-weight: bold;
    line-height: 1.2;
    color: $red;
  }

  .egos-details {
    background: $red;
    height: 90px;
    margin: 20px 0;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      font-size: 36px;
      color: $white;
    }

    &__img{
      width: 40px;
      height: 40px;

      img{
        width: 100%;
        cursor: pointer;
      }
    }

    &__text {
      text-align: center;

      p {
        text-transform: uppercase;
        color: $body-background;
        font-weight: bold;
        letter-spacing: 1.5px;
        font-size: 18px;
        margin: 5px 0;
      }
    }
  }

  .main-details {
    padding: 50px 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    .main-details__text {
      color: $white;
      text-align: left;
      margin: 0 10px;

      h3 {
        font-weight: normal;
        font-size: 21px;
        margin: 0;
      }

      p {
        margin: 5px 0;
        font-size: 18px;
      }
    }

    .main-details__buttons {
      display: flex;
    }
  }

  .slick-slider {
    .slick-prev,
    .slick-next {
      width: 40px;
      height: 40px;
      &::before {
        font-size: 40px;
      }
    }
    .slick-slide {
      div {
        width: 238px;
        margin: 0 auto;
        .card-wrapper {
          background: $white;
          box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.6);
          margin: 15px auto;
          outline: none;
          .card {
            height: 168px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .slick-dots {
      li {
        margin: 0px 2px;

        button {
          &::before {
            font-size: 18px;
            color: $jade;
            opacity: 1;
          }
        }

        &.slick-active {
          button {
            &::before {
              color: $white;
            }
          }
        }
      }
    }
  }

  .slide-link{
    text-align: right;
    margin-top: 120px;
    color: $white;
  }
 
}

@media screen and (max-width: 1215px) {
  .slide-1 {
    .slick-slider {
      width: 700px;
      margin: 0 auto;
    }

  }
}

@media screen and (max-width: 768px) {
  .slide-1 {
    .main-details {
      align-items: normal;
      flex-direction: column;

      .main-details__text {
        margin-bottom: 30px;
      }

      .main-details__buttons {
        flex-direction: column;
        align-items: center;
      }
    }
    .slick-slider {
      width: 400px;
    }
  }
}

@media screen and (max-width: 500px) {
  .slide-1 {
    .slick-slider {
      width: 290px;
    }
    .egos-details{
      h2 {
        font-size: 25px;
      }
    }
  }
}