@import "../styles/variables.scss";

.slide-4 {
  width: 300px;
  height: 100%;
  margin: 15px auto;
  h2 {
    margin-top: 20px;
    font-size: 30px;
    span {
      color: $white;
    }
  }

  .egos-icon,
  .smile-icon {
    color: $white;
    margin: 40px 0;
  }
}
