.slide-13 {
  position: relative;

  .slide-video {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 2500px;
    height: calc(100% - 55px);
    border: none;
    margin: 0 auto;
    padding: 0;
  }
  
  .slide-buttons {
    position: fixed;
    bottom: 0;
  }
}
