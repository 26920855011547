.slide-2 {
    width: 300px;
    margin: 0 auto;
    .slide-img {
        margin: 40px 0;

        img{
            width: 100%;
            box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.6)
        }
    }
}
