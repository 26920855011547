@import "../styles/variables.scss";

.slide-11 {
  &.chatsWrapper {
    max-width: 450px;
    margin: 0 auto;

    .messagesWrapper {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 300px);
      overflow-y: auto;
      -webkit-overflow-scrolling: auto;
      padding-top: 20px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
      background-color: #896b91;
      position: relative;
      -ms-overflow-style: none;
      scrollbar-width: none;
      
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .inputWrapper {
      display: flex;
      background-color: #6c4675;
      height: 200px;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }

    .button {
      width: 300px;
      min-height: 50px;
      background-color: #c082d0;
      border: none;
      outline: none;
      cursor: pointer;
      color: #ffffff;
      font-size: 16px;
      box-shadow: 0 4px 2px -2px #000000;
    }

    .messageBlocks,
    .userMessageBlocks {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      max-width: 40%;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
      padding: 0 10px 0 10px;
      font-weight: 700;
      border-radius: 5px;
      margin: 0px 10px 15px;
      position: relative;
      min-height: min-content;
      clear: both;

      p {
        padding: 10px 0 5px 0;

        &::before {
          content: "";
          width: 0px;
          height: 0px;
          display: block;
          position: absolute;
          top: 10px;
        }
      }
    }

    .messageBlocks {
      color: #6c4675;
      background-color: #ffffff;

      p {
        &::before {
          border-left: 5px solid transparent;
          border-right: 5px solid #ffffff;
          border-top: 5px solid #ffffff;
          border-bottom: 5px solid transparent;
          left: -10px;
        }
      }
    }

    .userMessageBlocks {
      background-color: #c082d0;
      color: #ffffff;
      align-self: flex-end;

      p {
        &::before {
          border-left: 5px solid #c082d0;
          border-right: 5px solid transparent;
          border-top: 5px solid #c082d0;
          border-bottom: 5px solid transparent;
          right: -10px;
        }
      }
    }

    .image {
      width: 35px;
      height: 35px;
      padding: 5px;
    }

    .fullImage {
      display: flex;
      width: 100%;
      object-fit: cover;
      background-position: center;
      border-radius: 5px;
      padding-bottom: 5px;
    }

    .loader-wrapper {
      position: relative;

      .loader,
      .loader::before,
      .loader::after {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: loader 1.8s infinite ease-in-out;
        animation: loader 1.8s infinite ease-in-out;
      }

      .loader {
        color: $white;
        font-size: 2px;
        margin: 80px auto;
        position: relative;
        text-indent: -9999em;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
        position: absolute;
        bottom: -80px;
        left: 25px;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
        }

        &::before {
          left: -3.5em;
          -webkit-animation-delay: -0.32s;
          animation-delay: -0.32s;
        }

        &::after {
          left: 3.5em;
        }
      }

      .black {
        color: $black;
      }

      @-webkit-keyframes loader {

        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }

        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }

      @keyframes loader {

        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }

        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
    }

    .slide-link {
      text-align: right;
    }
  }
}
