@import "../styles/variables.scss";

.slide-10 {
  position: relative;

  h2 {
    .egos {
      color: $white;
      display: flex;
      align-items: flex-end;

      .egos__number {
        span {
          font-size: 60px;
          margin-right: 10px;
        }
      }
    }
  }

  .egos-icon {
    color: $white;
    margin: 40px 0;
  }
}
