@import "../static/fonts/main.scss";
@import "./variables.scss";

body {
  margin: 0;
  font-family: Calibre, sans-serif;
  background-color: $body-background;
}

#root,
.App {
  height: 100%;
}

.container {
  max-width: 1140px;
  padding: 0 15px;
  margin: 15px auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.slide {
  overflow-x: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.slides {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-family: Calibre;
    font-size: 40px;
    color: $red;
  }
}

.start-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  margin: 15px 0;

  .start-button__img {
    width: 120px;
    height: 40px;
    cursor: pointer;
    animation: bounce 0.9s infinite;
    text-align: center;
    margin-bottom: 15px;
  }

  .start-button__text {
    font-family: CalibreBold;
    font-size: 40px;
    color: $red;
  }
}

.slide-buttons {
  button {
    width: 185px;
    display: block;
    margin: 10px;
    background: $purple;
    padding: 5px 10px;
    transition: .6s all;
    color: $white;

    &:hover {
      background: $white;
      color: black;
    }
  }
}

.slide-link{
  button{
    color: $white;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0);
  }
}
