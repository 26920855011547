@import "../styles/variables.scss";

.slide-12 {
  .receipt {
    background: white;
    font-size: 20px;
    max-width: 320px;
    text-align: left;
    padding: 15px;
    box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.6);

    .font-size-48 {
      font-size: 30px;
      line-height: 30px;
    }

    .font-bold {
      font-family: CalibreBold;
      font-weight: bold;
    }

    .grafic-icon {
      width: 50px;
      height: auto;
    }

    .tv-icon {
      width: 30px;
      height: auto;
      float: left;
      margin-right: 15px;
    }
  }

  .video-details {
    .slide-buttons {
      box-sizing: border-box;
      button {
        background-color: $red;

        &:hover {
          background-color: $purple;
        }
        &:disabled {
          background-color: grey
        }
      }
    }
  }
}
