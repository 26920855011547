@import "../styles/variables.scss";

.slide-7 {
  position: relative;

  h2 {
    margin: 15px 0 30px;
    text-align: left;
  }

  .happy-icon {
    animation: pulse 2s infinite;
    display: flex;
    color: $violet;
    margin: 50px 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(5);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
