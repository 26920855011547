@import "../styles/variables.scss";

.slide-9 {
  position: relative;

  .egos-icon {
    margin: 50px 0;
    animation: pulse 2s infinite;
    color: $violet;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
