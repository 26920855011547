.slide-6 {
    width: 320px;
    height: 100%;
    margin: 15px auto;

    .smile-icon{
        margin: 30px 0;
        color: white;
    }
}
