@import "../styles/variables.scss";

.slide-8 {
  margin-top: 15px;
  width: 100%;
  height: 100%;

  .result {
    font-size: 80px;
    display: flex;
    margin: 10px 0 0;

    .result__your-point {
      color: $white;
    }

    .result__all-point {
      color: $violet;
    }
  }

  .icons {
    display: flex;
    width: 320px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 55px 0;

    .icons__item {
      color: $white;
      margin-bottom: 5px;
    }

    .icons__item--violet {
      color: $violet;
    }
  }
}
