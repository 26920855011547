.impressumDetailsModal {
  .dialog-title {
    text-align: right;
  }

  .underline {
    text-decoration: underline;
  }

  .image-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
