@import "../styles/variables.scss";

.slide-5 {
  margin: 0 auto;

  .count-down {
    margin-top: 30px;
    text-align: center;
    width: 100%;

    .count-down__number {
      font-family: Calibre;
      font-size: 40px;
      color: $red;
      animation: pulsation 2s infinite;
    }
  }

  h2 {
    text-align: center;
  }

  .small-happy-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 50px;
    margin-top: 30px;
    color: $white;
  }

  .progress-bar {
    height: 10px;
    width: 320px;
    background-color: $light-purple;

    .progress-bar__line {
      width: 0;
      height: 100%;
      background-color: $red;
      animation-name: progressBar;
      animation-duration: 5s;
      animation-timing-function: linear;
      animation-fill-mode: both;
    }
  }

  .video-container {
    width: 320px;
    height: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;

    .canvas {
      width: 100%;
      height: 100%;
      z-index: 103;
      position: absolute;
    }

    .rec {
      position: absolute;
      left: 20px;
      top: 10px;
      z-index: 104;
      display: flex;
      align-items: center;
      justify-content: center;

      .rec__text {
        font-size: 20px;
        margin-top: 7px;
        color: $white;
        font-family: CalibreBold;
      }

      .rec__circle {
        height: 20px;
        width: 20px;
        background-color: $red;
        animation: blink 1s infinite;
        margin-right: 10px;
        border-radius: 50%;
      }
    }

    .big-happy-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 102;
      color: $white;
      opacity: 0.2;
    }

    .video {
      width: 100%;
      height: 320px;
      z-index: 100;
      object-fit: cover;
    }

    .video-container__stick {
      position: absolute;
      background: $white;
      opacity: 0.5;
      z-index: 101;

      &:nth-child(1) {
        height: 42px;
        width: 6px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &:nth-child(2) {
        height: 6px;
        width: 42px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &:nth-child(3) {
        height: 42px;
        width: 6px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &:nth-child(4) {
        height: 6px;
        width: 42px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

@keyframes progressBar {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulsation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
